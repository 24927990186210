import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ISettingGroup, ISettingType } from '@bs/models/accounts/settings-templates';
import { ICity, ICountry, IProvince, IRegion } from '@bs/models/common/country';
import { ICurrency } from '@bs/models/common/currency';
import { EnvironmentConfig } from '@bs/models/common/environment-config';
import { IdName } from '@bs/models/common/id-name';
import { IPrefix } from '@bs/models/common/prefix';
import { IDocumentType } from '@bs/models/me/documents';
import { IPaymentMethodsType } from '@bs/models/wallet/payment-method';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  readonly url = `${this.config.api.catalogs}`;
  currencies$: BehaviorSubject<Array<ICurrency>>;

  private _prefixes = this.http.get<Array<IPrefix>>(`${this.url}/prefixes`).pipe(shareReplay(1));
  private _countries = this.http.get<Array<ICountry>>(`${this.url}/geo/countries`).pipe(shareReplay(1));
  private _currencies = this.http.get<Array<ICurrency>>(`${this.url}/currencies`).pipe(shareReplay(1));

  /**
   * @ignore
   */
  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  /**
   * @returns the current currencies list of the website
   */
  get currentCurrencies(): Array<ICurrency> {
    return this.currencies$ ? this.currencies$.getValue() : [];
  }

  /**
   * @returns the current selected currency, default of the website, when the user logs should be refreshed
   */
  get currentCurrency(): ICurrency {
    return this.currentCurrencies.find(x => x.selected);
  }

  /**
   * set the currency of the website, override the default one
   */
  set currentCurrency(currency: ICurrency) {
    this.currentCurrencies.forEach(c => c.selected = c.id === currency.id);
  }

  /**
   * @returns prefixes for the app, prefixes are cached in memory after first call because they are static for each bookmakerId
   */
  prefixes(): Observable<Array<IPrefix>> {
    return this._prefixes;
  }

  /**
   * @returns prefixes for the app, prefixes are cached in memory after first call because they are static for each bookmakerId
   */
  prefixesByBookmaker(bookmakerId: number): Observable<Array<IPrefix>> {
    return this.http.get<Array<IPrefix>>(`${this.url}/prefixes`, { headers: { 'bookmakerId': bookmakerId.toString() } });
  }

  /**
   * @returns Array of currencies for the app, currencies are cached in memory after first call because they are static for each bookmakerId
   */
  currencies(): Observable<Array<ICurrency>> {
    return this._currencies.pipe(tap(currencies => {
      currencies[0].selected = true;
      this.currencies$ = new BehaviorSubject<Array<ICurrency>>(currencies);
    }));
  }

  /**
   * @returns promise like object of Currency from the provided id
   * @param id
   */

  /*currency(id: number): Observable<ICurrency> {
    return this.http.get<ICurrency>(`${this.url}/currencies/${id}`);
  }*/

  /**
   * GET /geo/countries
   * countries are cached in memory after first call because they are static for each bookmakerId
   * @returns countries for the app as Array of countries
   */
  countries(): Observable<Array<ICountry>> {
    return this._countries;
  }

  /**
   * GET /geo/countries/:ID/regions
   * @returns array of regions or 404
   * @param country
   */
  regions(country: ICountry): Observable<Array<IRegion>> {
    return this.http.get<Array<IRegion>>(`${this.url}/geo/countries/${country.id}/regions`);
  }

  /**
   * GET /geo/regions/:ID/provinces
   * @returns array provinces or 404
   * @param region
   */
  provinces(region: IRegion): Observable<Array<IProvince>> {
    return this.http.get<Array<IProvince>>(`${this.url}/geo/regions/${region.id}/provinces`);
  }

  /**
   * GET /geo/provinces/:ID/cities
   * @returns Array cities or 404
   * @param province
   */
  cities(province: IProvince): Observable<Array<ICity>> {
    return this.http.get<Array<ICity>>(`${this.url}/geo/provinces/${province.id}/cities`);
  }

  /**
   * GET /causalGroups
   * @returns causal groups by wallet id as Array of id, name
   * @param walletTypeId
   * @param bookmakers
   */
  causalGroups(walletTypeId: number, bookmakers: number[]): Observable<Array<IdName<any>>> {
    return this.http.get<Array<IdName<any>>>(`${this.url}/causalsGroup?w=${walletTypeId}&b=${bookmakers}`);
  }

  /**
   *
   * @param walletTypeId
   * @param groupId
   * @param bookmakers
   * @returns the causals from the walletId and groupId
   */
  causals(walletTypeId: number, groupId: number, bookmakers: number[]): Observable<Array<IdName<any>>> {
    return this.http.get<Array<IdName<any>>>(`${this.url}/causals?w=${walletTypeId}&g=${groupId}&b=${bookmakers}`);
  }

  /**
   * @param countryCode
   * @returns Array<IdName<string>> list of documents for the selected country or ALL documents available
   */
  documentTypes(countryCode?: string): Observable<Array<IDocumentType>> {
    const params: Params = {};

    if (countryCode) {
      params.c = countryCode;
    }

    return this.http.get<Array<IDocumentType>>(`${this.url}/documentTypes`, { params });
  }

  /**
   * @returns Array<ISettingType> list of settingTypes
   */
  settingTypes(bookmakerId: number, settingTemplateTypeId: number): Observable<Array<ISettingType>> {
    const params: Params = { b: bookmakerId, t: settingTemplateTypeId };

    return this.http.get<Array<ISettingType>>(`${this.url}/catalogs/setting-types`, { params });
  }

  updateSettingType(settingTypeId: number, req: { bookmakerId: number; isEnabled: boolean }): Observable<ISettingType> {
    return this.http.patch<ISettingType>(`${this.url}/catalogs/setting-types/${settingTypeId}`, req);
  }

  /**
   * @returns Array<ISettingGroup> list of settingTypes
   */
  settingGroups(): Observable<Array<ISettingGroup>> {
    return this.http.get<Array<ISettingGroup>>(`${this.url}/catalogs/setting-groups`);
  }

  /**
   * @returns Array<PaymentMethodType> list of paymentMethodType
   */
  paymentMethodsTypes(bookmakerId: number): Observable<Array<IPaymentMethodsType>> {
    const params: Params = {};
    params.b = bookmakerId;

    return this.http.get<Array<IPaymentMethodsType>>(`${this.url}/catalogs/payment-methods-types`, { params });

  }

  /*getMovementStates(): Observable<any> {
    return this.client.get(this.paths.movementStates);
  }*/

  /*getSportbetFilters(): Promise<ISportbetFilterObject> {
    return this.client.get<ISportbetFilterObject>(this.paths.sportbetfilters);
  }*/

  /*getWalletTypes(): Observable<any> {
    return this.client.get(this.paths.walletTypes);
  }*/
}
